import createProps from '@kissui/helpers/src/props.helpers'
import { stringifyForAttribute } from '@kissui/helpers/src/utils'
import { getMarketCode, getLangCode, interpolateMarketLang } from '@kissui/helpers/src/dataLayer'
import { buildSchema } from './services/ldjson'
import { appendSchema } from '@kissui/helpers/src/htmlElement'
import { handlePromoClick } from '@kissui/helpers/src/gtmEvents'

class FooterBottom extends HTMLElement {
    constructor() {
        super()
        this.market = getMarketCode()
        this.language = getLangCode()
        this.btnScrollToTop = null
        this.dropdownButtons = null
    }

    connectedCallback() {
        this.props = createProps(this.attributes)
        this.render()
        this.addSchema()
    }

    render() {
        this.unbindEvent()

        const { id, name, creative, position } = this.props.campaign

        this.innerHTML = `<nb-container
                campaign_id="${id}"
                campaign_name="${name}"
                campaign_creative="${creative}"
                campaign_position="${position}"
                classname="pt0 pb0"
                contrast="light">
                <div class="cb-row">
                    <div class="country">${this.renderCountry()}</div>
                    <div class="links">
                      <div class="big-links t-xs-500-sl">${this.renderBigLinks()}</div>
                      <div class="small-links t-2xs-500-sl">${this.renderSmallLinks()}</div>
                    </div>
                </div>
                <div class="cb-row t-xs-500-sl">
                    <div class="socials">${this.renderSocialLinks()}</div>
                    <div class="pagetop">${this.renderPageTop()}</div>
                    <div class="copyright">${this.renderCopyright()}</div>
                </div>
            </nb-container>`

        this.btnScrollToTop = this.querySelector('.pagetop a')
        // Todo: check with Fabien if can avoid this code, having a real <A> instead of button + data-link
        this.dropdownButtons = this.querySelectorAll('.nb-dropdown__menu button')
        this.allLinkElements = this.querySelectorAll('ul li a')
        this.bindEvent()
    }

    renderCountry() {
        const { label_dropdown, aa_description, aa_listbox_description, aa_select_description } =
            this.props.copywriting.languages.dropdown
        const { langs } = this.props.copywriting.languages
        const LANG_MENU_ID = 'lang_menu_id'

        this.getCurrentURLs()

        return `${this.renderFlag()}
        ${
            langs.length === 0
                ? ''
                : `<nb-dropdown
                    id="${LANG_MENU_ID}"
                    data="${stringifyForAttribute({
                        label: label_dropdown,
                        aa_description: aa_description,
                        aa_listbox_description: aa_listbox_description,
                        aa_select_description: aa_select_description,
                        icon_option: '16/symbol/check',
                        options: langs
                    })}"/>`
        }`
    }

    renderFlag() {
        let { label_a11y, label_country, icon, location_link } =
            this.props.copywriting.languages.country
        const { label, link } = location_link

        // TODO: Disable this when HQ officially communicate it.
        if (this.market === 'tw') {
            icon = '24/symbol/globe'
        }

        let flagRender = `<img loading="lazy" src="https://www.nespresso.com/_ui/img/flags/${this.market}.png?impolicy=small&imwidth=24" alt="${label_country}" aria-hidden="true" />`
        if (icon === 'globe') {
            flagRender = `<nb-icon icon="24/symbol/${icon}"></nb-icon>`
        }

        return `<a href="${interpolateMarketLang(
            link,
            this.market,
            this.language
        )}" target="_self" aria-label="${label} ${label_a11y} ${label_country}" class="icon">
            ${flagRender}
        </a>`
    }

    isPro() {
        return window.location.pathname.startsWith('/pro')
    }

    getCurrentURLs() {
        const { langs } = this.props.copywriting.languages
        const currentURL = window.location.href

        langs.forEach(item => {
            item.link = this.replaceLang(currentURL, item.code)
            item.checked = item.code === this.language
        })
    }

    replaceLang(url, code) {
        // fix missing language (like www.nespresso.com/us/)
        if (
            url.indexOf(`/${this.market}/${this.language}`) === -1 &&
            url.indexOf(`/${this.market}`) >= 0
        ) {
            return url.replace(`/${this.market}`, `/${this.market}/${code}`)
        }
        // Normal use case with (like www.nespresso.com/us/en)
        return url.replace(`/${this.market}/${this.language}`, `/${this.market}/${code}`)
    }

    renderBigLinks() {
        const { big_links } = this.props.copywriting

        return `${this.renderItems(big_links)}`
    }

    renderSmallLinks() {
        const { small_links } = this.props.copywriting

        return `${this.renderItems(small_links)}`
    }

    renderSocialLinks() {
        const { heading, links } = this.props.copywriting.socials

        return `<p>${heading}</p>${this.renderItems(links)}`
    }

    renderPageTop() {
        const { page_top } = this.props.copywriting

        // Before changing this <a> to a <button> please remember that <a> shares css fix for hover bold not jumping
        // For me this <a> is the good semantic as a scrolltop is usually an anchor
        // local test window.scrollTo({ top: 0, behavior: 'smooth' }); window.document.querySelector('.country a').closest('a').focus()
        return `<a href="javascript:void(0)" phantom="${page_top}" aria-label="${page_top}"><span aria-hidden="true">${page_top}</span><nb-icon icon="24/symbol/arrow-top"></nb-icon></a>`
    }

    getOriginUrl() {
        return `https://www.nespresso.com/${this.isPro() ? 'pro/' : ''}${this.market}/${
            this.language
        }`
    }

    renderCopyright() {
        const { homepage_label, copyright, copyright_a11y } = this.props.copywriting
        const year = new Date().getFullYear()

        let logo = 'nespresso-logo-dark'
        if (this.isPro()) {
            logo = 'nespresso-logo-b2b-dark'

            if (this.language === 'fr') {
                logo = 'nespresso-pro-logo-fr'
            }
        }

        return `<a href="${this.getOriginUrl()}"
                target="_self" aria-label="${homepage_label}">
            <nb-icon icon="logotypes/nespresso/${logo}"></nb-icon>
        </a><p><span class="sr-only">${copyright_a11y + ' '}</span>${copyright} ${year}</p>`
    }

    renderItems(items) {
        const renderedItems = items.reduce(
            (output, item) => `${output}${this.renderItem(item)}`,
            ''
        )

        return `<ul>${renderedItems}</ul>`
    }

    renderItem({ label, link, icon = '', script = 'false', target_blank }) {
        const url = interpolateMarketLang(link, this.market, this.language)

        // Social icon link
        if (icon.trim()) {
            return `<li><a href="${url}" target="_blank" aria-label="${label}"><nb-icon icon="${icon}"></nb-icon></a></li>`
        }

        // Script for cookie link
        if (script === 'true') {
            return `<li><a href="javascript:void(0)" phantom="${label}" onclick="try{ah.optOut()}catch(e){}" aria-label="${label}"><span aria-hidden="true">${label}</span></a></li>`
        }

        // Other links
        let target = '_self'
        if (target_blank === 'true') {
            target = '_blank'
        }
        return `<li><a href="${url}" target="${target}" phantom="${label}" aria-label="${label}"><span aria-hidden="true">${label}</span></a></li>`
    }

    addSchema() {
        const { links } = this.props.copywriting.socials
        const socialLinks = links.map(({ link }) => link)

        const content = buildSchema({
            market: this.market,
            url: this.getOriginUrl(),
            urlLogo:
                'https://www.nespresso.com/shared_res/agility/next-components/assets/icons/logotypes/nespresso/nespresso-logo-dark.svg',
            sameAs: socialLinks,
            contact: this.props.copywriting.contact
        })
        appendSchema(content)
    }

    scroll() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        window.document.querySelector('.Header__top a').focus()
    }

    // Todo: check with Fabien if can avoid this code, having a real <A> instead of button + data-link
    // Todo : in fact, we could have a real <A> in dropdown to avoid this complexity
    // Todo : but this needs to be changed also in plp-sticky-bar that uses the same pattern
    navigate(e) {
        const link = e.target.getAttribute('data-link')
        if (link) {
            window.location.href = link
        }
    }

    linkClick({ textContent, index }) {
        const { campaign } = this.props

        handlePromoClick({
            campaign,
            cta_name: `LINK - ${index + 1} - ${textContent}`
        })
    }

    bindEvent() {
        if (this.allLinkElements?.length) {
            this.allLinkElements.forEach((element, index) => {
                const textContent = element.textContent
                element.boundLinkClick = this.linkClick.bind(this, { textContent, index })
                element.addEventListener('click', element.boundLinkClick)
            })
        }

        if (this.btnScrollToTop) {
            this.boundScroll = this.scroll.bind(this)
            this.btnScrollToTop.addEventListener('click', this.boundScroll)
        }
        // Todo: check with Fabien if can avoid this code, having a real <A> instead of button + data-link
        if (this.dropdownButtons?.length) {
            this.dropdownButtons.forEach(element => {
                element.boundClick = this.navigate.bind(element)
                element.addEventListener('click', element.boundClick)
            })
        }
    }

    unbindEvent() {
        if (this.allLinkElements?.length) {
            this.allLinkElements.forEach(element => {
                element.removeEventListener('click', element.boundLinkClick)
            })
        }

        if (this.btnScrollToTop) {
            this.btnScrollToTop.removeEventListener('click', this.boundScroll)
        }
        // Todo: check with Fabien if can avoid this code, having a real <A> instead of button + data-link
        if (this.dropdownButtons?.length) {
            this.dropdownButtons.forEach(element => {
                element.removeEventListener('click', element.boundClick)
            })
        }
    }

    disconnectedCallback() {
        this.unbindEvent()
    }
}

customElements.get('nb-footer-bottom') || customElements.define('nb-footer-bottom', FooterBottom)
export default FooterBottom
